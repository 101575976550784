<template>
  <vue-tabs>

    <!-- TAB DE USUARIOS -->
    <v-tab title="Usuarios" icon="nc-icon nc-circle-09" style="width: 100%;">

      <div class="row">
        <div class="col-md-6">
            <card class="horizontal-form" title="Horizontal Form">
              <div slot="header">
                  <h4 class="card-title">Usuarios</h4>
                  <p class="card-category">Lista, Edición y Borrado de fichas</p>
              </div>
              <div class="table-responsive table-full-width">
                <el-table :data="tableData" :row-class-name="tableRowClassName">
                    <el-table-column label="Usuario" property="usuario" width="180"></el-table-column>
                    <el-table-column label="Password" property="password" width="100"></el-table-column>
                    <el-table-column label="Perfil" property="perfil" width="80"></el-table-column>
                    <el-table-column label="Acciones">
                      <template slot-scope="props">
                          <a class="btn-neutral btn-simple btn-link btn-lg2" v-tooltip.top-center="'Edita'" @click="handleEdit(props.$index, props.row)">
                            <i class="fa fa-edit"></i>
                          </a>
                          <a class="btn-danger btn-simple btn-link btn-lg2" v-tooltip.top-center="'Borra'" @click="handleDelete(props.$index, props.row)">
                            <i class="fa fa-times"></i>
                          </a>
                          <a class="btn-neutral btn-simple btn-link btn-lg2" v-tooltip.top-center="'Copia esta plantilla al seleccionado en ficha de usuario'" @click="handleDuplica(props.$index, props.row)">
                            <i class="fa fa-files-o"></i>
                          </a>
                      </template>
                    </el-table-column>
                </el-table>
              </div>
            </card>
        </div> <!-- end card -->
        <div class="col-md-6">
          <card class="stacked-form" title="Ficha de Usuario">
            <template slot="header">
              <h4 class="card-title">
                Ficha de Usuario
              </h4>
            </template>
            <div class="col-12">
              <fg-input label="id"
                disabled=""
                inputClasses="col-md-3 col-3"
                placeholder="Id"
                v-model="usuario.id">
              </fg-input>
              <fg-input label="Usuario"
                inputClasses="col-md-12 col-12"
                placeholder="Usuario"
                v-model="usuario.usuario">
              </fg-input>
              <fg-input label="Contraseña"
                inputClasses="col-md-9 col-9"
                placeholder="Contraseña"
                v-model="usuario.password">
              </fg-input>
              <fg-input label="Tipo de Perfil">
                <radio label="1" value="1" v-model="usuario.perfil">Cliente</radio>
                <radio label="2" value="2" v-model="usuario.perfil">Encargado</radio>
                <radio label="3" value="3" v-model="usuario.perfil">Administrador</radio>
              </fg-input>
              <fg-input label="Cliente"
                inputClasses="col-md-3 col-3"
                placeholder="Código de cliente IGP"
                v-model="usuario.cliente">
              </fg-input>
              <fg-input label="email"
                type="email"
                inputClasses="col-md-12 col-12"
                placeholder="Email"
                v-model="usuario.email">
              </fg-input>
              <fg-input label="Serie"
                inputClasses="col-md-3 col-3"
                placeholder="Serie IGP"
                v-model="usuario.serie">
              </fg-input>
              <!--<fg-input label="Tipo de Recipiente">
                <radio label="1" value="1" v-model="usuario.recipiente">Recipiente 20l.</radio>
                <radio label="2" value="2" v-model="usuario.recipiente">Recipiente 5,5l.</radio>
              </fg-input>-->
            </div>
            <l-button type="primary" v-if="(usuario.id!==undefined) || (usuario.id==undefined && usuario.usuario!==undefined)" @click="limpia" style="margin-left: 10px;">
              Limpia
            </l-button>
            <l-button type="primary" v-if="usuario.id!==undefined" @click="modifica" style="margin-left: 10px;">
              Modifica
            </l-button>
            <l-button type="primary" v-if="usuario.id==undefined && usuario.usuario!==undefined" @click="alta" style="margin-left: 10px;">
              Alta
            </l-button>
          </card>
        </div> <!-- end card -->
      </div>



    <!-- TAB DE ASIGNACION DE PLANTILLA -->
    </v-tab>
    <v-tab title="Plantilla" icon="nc-icon nc-ruler-pencil" style="width: 100%;">

      <div class="row">
        <div class="col-md-6">
            <card class="vertical-form" title="Horizontal Form" style="height: calc(100vh - 80px);">
              <div slot="header">
                  <h4 class="card-title">Productos</h4>
                  <p class="card-category">Asignación a plantilla</p>
              </div>
              <div class="table-responsive table-full-width">
                <el-table :data="tableData2" :row-class-name="tableRowClassName2" style="overflow: scroll; height: calc(100vh - 200px);">
                    <el-table-column label="Nombre" property="nombre" width="220"></el-table-column>
                    <el-table-column label="Familia" property="nomfam" width="100"></el-table-column>
                    <el-table-column label="Referencia" property="referencia" width="100"></el-table-column>
                    <el-table-column label="Acciones">
                      <template slot-scope="props">
                          <a class="btn-neutral btn-simple btn-link" v-tooltip.top-center="'Asigna'" @click="manejaAsigna(props.$index, props.row)">
                            <i class="fa fa-hand-o-right"></i>
                          </a>
                          <a class="btn-neutral btn-simple btn-link" v-tooltip.top-center="'Desasigna'" @click="manejaDesasigna(props.$index, props.row)">
                            <i class="fa fa-hand-o-left"></i>
                          </a>
                      </template>
                    </el-table-column>
                </el-table>
              </div>

            </card>
        </div> <!-- end card -->
        <div class="col-md-6">
          <card class="stacked-form" title="Ficha de Producto">
            <template slot="header">
              <h4 class="card-title">
                Plantilla de Usuario {{usuario.usuario}}
              </h4>
            </template>
            <div class="table-responsive table-full-width">
              <el-table v-if="usuario.id!=undefined" :data="tableData3" :row-class-name="tableRowClassName3" style="overflow: scroll; height: calc(100vh - 200px);">
                  <el-table-column label="Producto" property="nomprod" width="220"></el-table-column>
                  <el-table-column label="Acciones">
                    <template slot-scope="props">
                        <a class="btn-neutral btn-simple btn-link" v-tooltip.top-center="'Sube'" @click="handleSube(props.$index, props.row)">
                          <i class="fa fa-hand-o-up"></i>
                        </a>
                        <a class="btn-neutral btn-simple btn-link" v-tooltip.top-center="'Baja'" @click="handleBaja(props.$index, props.row)">
                          <i class="fa fa-hand-o-down"></i>
                        </a>
                    </template>
                  </el-table-column>
              </el-table>
            </div>
            <br>
          </card>
        </div> <!-- end card -->
      </div>
    </v-tab>
  </vue-tabs>
</template>
<script>
  import Swal from 'sweetalert2'
  import 'sweetalert2/dist/sweetalert2.css'
  import {Checkbox, Radio} from 'src/components/index'
  import {Table, TableColumn} from 'element-ui'
  import {VTab, VueTabs} from 'vue-nav-tabs'
  import 'vue-nav-tabs/themes/vue-tabs.scss'


  import axios from "axios";
  import crypt from "crypto-js";
  import AES from "crypto-js/aes";

  export default {
    components: {
      Checkbox,
      Radio,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      VueTabs,
      VTab
    },
    data () {
      return {
        tableData: [],
        tableData2: [],
        tableData3: [],
        usuario: {usuario:'',password:'',perfil:'1',cliente:'0',email:'',serie:'1'},

        producto: {},
        familias: {},
        ultorden: 0,

        colores: [{value: '1', label: '', color: 'bc1'},
        {value: '2', label: '', color: 'bc2'},
        {value: '3', label: '', color: 'bc3'},
        {value: '4', label: '', color: 'bc4'},
        {value: '5', label: '', color: 'bc5'},
        {value: '6', label: '', color: 'bc6'},
        {value: '7', label: '', color: 'bc7'},
        {value: '8', label: '', color: 'bc8'},
        {value: '9', label: '', color: 'bc9'},
        {value: '10', label: '', color: 'bc10'},
        {value: '11', label: '', color: 'bc11'},
        {value: '12', label: '', color: 'bc12'},
        {value: '13', label: '', color: 'bc13'},
        {value: '14', label: '', color: 'bc14'},
        {value: '15', label: '', color: 'bc15'},
        {value: '16', label: '', color: 'bc16'},
        {value: '17', label: '', color: 'bc17'},
        {value: '18', label: '', color: 'bc18'},
        {value: '19', label: '', color: 'bc19'},
        {value: '20', label: '', color: 'bc20'},
        {value: '21', label: '', color: 'bc21'}]

      }
    },
    created() {
      this.cargafamilias()
      this.cargaproductos()
    },
    mounted() {
      this.cargalista()


    },
    methods: {
      cargalista() {
        var mijson = {
          funcion: "lista",
          tabla: "usuarios",
          campos: "*",
          where: "",
          orden: ""
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          this.tableData = resp
          //this.cargaplantilla()
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      tableRowClassName(row) {
        console.log(row.row.perfil)
        if (row.row.perfil == 2) {
          return 'encargado'
        } else if (row.row.perfil == 3) {
          return 'administrador'
        }
        return ''
      },
      handleEdit(index, row) {
        this.usuario = row
        this.cargaplantilla()
      },
      handleDelete(index, row) {
        this.usuario = row
        var borrusuario = this.usuario.usuario
        var self = this
        Swal.fire({
          title: 'Estás seguro?',
          text: 'Realmente quieres borrar al usuario: '+borrusuario,
          icon: 'question',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Si, borrarlo!',
          cancelButtonText: 'Cancelar',
          buttonsStyling: false
        }).then(function (valor) {
          if (valor.isDismissed == true) {
            console.log("cancelaron")
          } else {
            self.baja()
            Swal.fire({
              title: '¡Atención!',
              text: '¡Ficha de usuario '+borrusuario+' eliminada!',
              timer: 1500,
              showConfirmButton: false
            })
          }
        })
      },
      handleDuplica(index, row) {
        console.log("id del que duplicar: ",row.id)
        console.log("id al que duplicar: ",this.usuario.id)
        if (this.usuario.id!=undefined && this.usuario.id>0) {
          var mijson = {
            funcion: "duplica",
            tabla: "plantillas",
            campos: "producto_id, orden",
            campoold: "usuario_id",
            valorold: row.id,
            valornew: this.usuario.id
          }
          const params = new URLSearchParams();
          params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
          axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
          .then(respuesta => {
            var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
            console.log(resp)
            if (resp.resp=="OK") {
              this.cargaplantilla()
            }
          })
          .catch(error => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
          })
        } else {
          Swal.fire({
            title: '¡Atención!',
            text: 'Ficha de usuario destino no seleccionada',
            timer: 3000,
            showConfirmButton: false
          })
        }




      },
      limpia() {
        this.usuario={usuario:'',password:'',perfil:'1',cliente:'0',email:'',serie:'1'}
      },
      modifica() {
        var mijson = {
          funcion: "modifica",
          tabla: "usuarios",
          campos: "usuario, password, perfil, cliente, email, serie",
          valores: "'"+this.usuario.usuario+"', '"+this.usuario.password+"', '"+this.usuario.perfil+"', '"+this.usuario.cliente+"', '"+this.usuario.email+"', '"+this.usuario.serie+"'",
          id: String(this.usuario.id)
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          console.log(resp)
          if (resp.resp=="OK") {
            this.cargalista()
          }
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      alta() {
        var mijson = {
          funcion: "alta",
          tabla: "usuarios",
          campos: "usuario, password, perfil, cliente, email, serie",
          valores: "'"+this.usuario.usuario+"', '"+this.usuario.password+"', '"+this.usuario.perfil+"', '"+this.usuario.cliente+"', '"+this.usuario.email+"', '"+this.usuario.serie+"'"
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          console.log(resp)
          if (resp.resp=="OK") {
            this.cargalista()
          }
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      baja() {
        var mijson = {
          funcion: "baja",
          tabla: "usuarios",
          id: String(this.usuario.id)
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          console.log(resp)
          if (resp.resp=="OK") {
            this.usuario={}
            this.cargalista()
          }
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      cargafamilias() {
        var mijson = {
          funcion: "lista",
          tabla: "familia",
          campos: "*",
          where: "",
          orden: ""
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          this.familias = resp
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      cargaproductos() {
        var mijson = {
          funcion: "listaLJ",
          tabla: "producto",
          campos: "a.id, a.nombre, a.familia_id, a.referencia",
          where: "",
          order: "a.familia_id, a.nombre",
          ljtabla: "familia",
          ljid: "familia_id",
          ljcampo: "nombre",
          ljalias: "nomfam"
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          this.tableData2 = resp
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      tableRowClassName2(row) {
        var micolor = 0
        if (this.usuario!=undefined) {
          this.familias.forEach( function(valor, indice, fam) {
            if (row.row.familia_id==valor.id) {
              micolor = valor.color
            }
          })
        }
        var clase = ''
        if (micolor>0) {
          clase = this.colores[micolor-1].color
        }
        return clase
      },
      manejaAsigna(index, row) {
        if (this.usuario.id>0 && this.usuario.id!=undefined) {
          var miorden = parseInt(this.ultorden)+1
          console.log(miorden)
          var mijson = {
            funcion: "altaNE",
            tabla: "plantillas",
            campos: "usuario_id, producto_id,orden",
            valores: "'"+this.usuario.id+"', '"+row.id+"', "+miorden,
            where: "usuario_id='"+this.usuario.id+"' and producto_id='"+row.id+"'"
          }
          const params = new URLSearchParams();
          params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
          axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
          .then(respuesta => {
            var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
            console.log(resp)
            if (resp.resp=="OK") {
              this.cargaplantilla()

            }
          })
          .catch(error => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
          })
        } else {
          Swal.fire({
            title: '¡Atención!',
            text: 'Ficha de usuario no seleccionada',
            timer: 3000,
            showConfirmButton: false
          })
        }
      },
      manejaDesasigna(index, row) {
        if (this.usuario.id>0 && this.usuario.id!=undefined) {
          var mijson = {
            funcion: "bajaWE",
            tabla: "plantillas",
            where: "usuario_id="+this.usuario.id+" and producto_id="+row.id
          }
          const params = new URLSearchParams();
          params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
          axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
          .then(respuesta => {
            var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
            console.log(resp)
            if (resp.resp=="OK") {
              this.cargaplantilla()
            }
          })
          .catch(error => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
          })
        } else {
          Swal.fire({
            title: '¡Atención!',
            text: 'Ficha de usuario no seleccionada',
            timer: 3000,
            showConfirmButton: false
          })
        }
      },
      tableRowClassName3(row) {
        var micolor = 0
        var mifamid = 0
        if (this.usuario!=undefined) {
          var misfamilias = this.familias
          this.tableData2.forEach( function(valor, indice) {
            if (valor.id==row.row.producto_id) {
              mifamid = valor.familia_id
              misfamilias.forEach(function(valor2, indice2) {
                if (mifamid==valor2.id) {
                  micolor = valor2.color
                }
              })
            }
          })
        }

        var clase = ''
        if (micolor>0) {
          clase = this.colores[micolor-1].color
        }
        return clase
      },
      handleSube(index, row) {
        if (index>0) {
          var miarr = this.tableData3
          moveUp(miarr, index);
          this.tableData3=miarr
          var contador = 1
          var estado = this.$store.state
          miarr.forEach(function(valor, indice) {
            var mijson = {
              funcion: "modifica",
              tabla: "plantillas",
              campos: "orden",
              valores: "'"+String(contador)+"'",
              id: String(valor.id)
            }
            const params = new URLSearchParams();
            params.append('cadena', AES.encrypt(JSON.stringify(mijson),estado.salvoconducto));
            axios.post('https://'+estado.urlserver+':'+estado.puertoserver, params)
            .then(respuesta => {
              var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, estado.salvoconducto)))
              //console.log(resp)
              if (resp.resp=="OK") {
              }
            })
            .catch(error => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
              } else if (error.request) {
                  console.log(error.request);
              } else {
                  console.log('Error', error.message);
              }
              console.log(error.config);
            })
            contador++
          })
        }
      },
      handleBaja(index, row) {
        if (index<(this.tableData3.length)-1) {
          var miarr = this.tableData3
          moveDown(miarr, index);
          this.tableData3=miarr
          var contador = 1
          var estado = this.$store.state
          miarr.forEach(function(valor, indice) {
            var mijson = {
              funcion: "modifica",
              tabla: "plantillas",
              campos: "orden",
              valores: "'"+String(contador)+"'",
              id: String(valor.id)
            }
            const params = new URLSearchParams();
            params.append('cadena', AES.encrypt(JSON.stringify(mijson),estado.salvoconducto));
            axios.post('https://'+estado.urlserver+':'+estado.puertoserver, params)
            .then(respuesta => {
              var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, estado.salvoconducto)))
              //console.log(resp)
              if (resp.resp=="OK") {
              }
            })
            .catch(error => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
              } else if (error.request) {
                  console.log(error.request);
              } else {
                  console.log('Error', error.message);
              }
              console.log(error.config);
            })
            contador++
          })
        }
      },
      cargaplantilla() {
        var mijson = {
          funcion: "listaLJ",
          tabla: "plantillas",
          campos: "a.id, a.usuario_id, a.producto_id, a.orden, a.cant",
          where: "a.usuario_id = "+String(this.usuario.id),
          order: "a.orden",
          ljtabla: "producto",
          ljid: "producto_id",
          ljcampo: "nombre",
          ljalias: "nomprod"
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          this.tableData3 = resp
          var ordmax = 0
          resp.forEach( function(valor, indice, fam) {
            if (parseInt(valor.orden)>ordmax) {
              ordmax = parseInt(valor.orden)
            }
          })
          this.ultorden = ordmax
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
    }


  }

  var move = function(array, index, delta) {
    //var index = array.indexOf(element);
    var newIndex = index + delta;
    if (newIndex < 0  || newIndex == array.length) return; //Already at the top or bottom.
    var indexes = [index, newIndex].sort(); //Sort the indixes
    array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); //Replace from lowest index, two elements, reverting the order
  };

  var moveUp = function(array, element) {
    move(array, element, -1);
  };

  var moveDown = function(array, element) {
    move(array, element, 1);
  };

</script>
<style>

  .el-table .encargado {
    background: #DAF7A6;
  }
  .el-table .administrador {
    background: #FFC300;
  }
  .vue-tabs .tab-content {
    padding-top: 10px;
    min-height: 100px;
    display: flex;

  }
  .vue-tabs .tab-content .tab-container {
     display: block;
     animation: fadeIn 0.5s;
   }

   .el-table .bc1 {
     background: #F08080;
   }
   .el-table .bc2 {
     background: #FFA07A;
   }
   .el-table .bc3 {
     background: #FFB6C1;
   }
   .el-table .bc4 {
     background: #FFA500;
   }
   .el-table .bc5 {
     background: #FFFACD;
   }
   .el-table .bc6 {
     background: #FFE4B5;
   }
   .el-table .bc7 {
     background: #F0E68C;
   }
   .el-table .bc8 {
     background: #E6E6FA;
   }
   .el-table .bc9 {
     background: #DDA0DD;
   }
   .el-table .bc10 {
     background: #66CDAA;
   }
   .el-table .bc11 {
     background: #3CB371;
   }
   .el-table .bc12 {
     background: #AFEEEE;
   }
   .el-table .bc13 {
     background: #B0C4DE;
   }
   .el-table .bc14 {
     background: #6495ED;
   }
   .el-table .bc15 {
     background: #FFEBCD;
   }
   .el-table .bc16 {
     background: #D2B48C;
   }
   .el-table .bc17 {
     background: #F0F8FF;
   }
   .el-table .bc18 {
     background: #FAEBD7;
   }
   .el-table .bc19 {
     background: #FAF0E6;
   }
   .el-table .bc20 {
     background: #FFF0F5;
   }
   .el-table .bc21 {
     background: #FFE4E1;
   }
</style>
